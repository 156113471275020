// Game.jsx
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, onSnapshot, getDoc } from 'firebase/firestore';
import { doc as firestoreDoc } from 'firebase/firestore'; // Importera doc som firestoreDoc för att undvika namnkonflikter
import Loading from '../Loading';
import './Game.css';
import translations from '../locales/translations';
import { useLanguage } from '../hooks/LanguageContext';
import formatTimeSince from './utils/formatTimeSince';
import searchIcon from './search.png'; // Import the search image
import MovelandComponent from './MovelandComponent'; // Import the new component
import ImageOverlay from './ImageOverlay'; // Lägg till importen för ImageOverlay
import placePin from './placepin.png';
import placePinned from './placepinned.png';



const Game = ({ setShowRoleDropdown }) => {
  const navigate = useNavigate();
  const [missions, setMissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeMissions, setActiveMissions] = useState([]);
const [selectedMissionId, setSelectedMissionId] = useState(() => {
  // Vid laddning av sidan återställs selectedMissionId till null
  return null;
});
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const selectedMissionRef = useRef(null);
  const selectedBackgroundRef = useRef(null);
  const { language } = useLanguage();
  const [completedMissions, setCompletedMissions] = useState([]);
  const missionRefs = useRef([]);
  const [filteredMissions, setFilteredMissions] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false); // State for search field focus
  const searchRef = useRef(null);
  const searchIconRef = useRef(null); // Ref for search icon
const [clickCounts, setClickCounts] = useState(() => {
  const savedCounts = localStorage.getItem('clickCounts');
  return savedCounts ? JSON.parse(savedCounts) : {};
});


  const [location, setLocation] = useState('');
const [isEditingLocation, setIsEditingLocation] = useState(false);


  const baseConfig = {
    initialTop: -100,
    scrollFactor: 1,
    topIncrement: 500, // Increment value for top position
    scrollFactorIncrement: 0.2, // Increment value for scroll factor
    heightIncreaseFactorIncrement: 0.05, // Increment value for height increase factor
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filtered = missions.filter((mission) =>
      mission.title?.toLowerCase().includes(searchTerm) ||
      mission.description?.toLowerCase().includes(searchTerm) ||
      (mission.keywords && mission.keywords.some((keyword) => keyword.toLowerCase().includes(searchTerm)))
    );
    setFilteredMissions(filtered);
  };


useEffect(() => {
  const db = getFirestore();
  const overviewDocRef = firestoreDoc(db, 'missionOverviews', 'liveMissions');

  setIsLoading(true);
  const unsubscribe = onSnapshot(overviewDocRef, async (doc) => {
    if (doc.exists()) {
      const missionsOverview = doc.data();
      let fetchedMissions = Object.keys(missionsOverview).map((key) => ({
        id: key,
        ...missionsOverview[key],
        createdAt: missionsOverview[key].createdAt ? new Date(missionsOverview[key].createdAt.seconds * 1000) : new Date(),
        deadline: missionsOverview[key].deadline ? new Date(missionsOverview[key].deadline.seconds * 1000) : new Date(),
        images: missionsOverview[key].images || [],
        likes: missionsOverview[key].likes || 0,
        animationDuration: `${15 + Math.random() * 45}s`,
        animationDelay: `${-20 + Math.random() * 10}s`,
        roles: missionsOverview[key].roles || [],
        topValue: missionsOverview[key].topValue,
        points: missionsOverview[key].points || 0,
      }));
      fetchedMissions.sort((a, b) => b.createdAt - a.createdAt);

      setMissions(fetchedMissions);
      setActiveMissions(fetchedMissions.slice(0, 20)); // Display initial 20 missions
      setFilteredMissions(fetchedMissions);
      setIsLoading(false);

      const missionPromises = fetchedMissions.map(async (mission) => {
        const missionDocRef = firestoreDoc(db, 'missions', mission.id);
        const missionDoc = await getDoc(missionDocRef);
        if (missionDoc.exists()) {
          return { ...mission, ...missionDoc.data() };
        }
        return mission;
      });

      const updatedMissions = await Promise.all(missionPromises);
      setMissions(updatedMissions);
      setActiveMissions(updatedMissions.slice(0, 20));
      setFilteredMissions(updatedMissions);
    } else {
      console.error('Mission overviews document does not exist');
      setIsLoading(false);
    }
  });

  return () => unsubscribe();
}, []);


useEffect(() => {
  const searchIconElement = searchIconRef.current;
  if (searchIconElement) {
    searchIconElement.classList.add('animate-in');
  }

  // Scrolla ned 400px när sidan laddas
  window.scrollTo(0, 400);
}, []);

const updateSelectedBackground = () => {
  if (selectedMissionRef.current && selectedBackgroundRef.current) {
    selectedBackgroundRef.current.style.top = `${selectedMissionRef.current.offsetTop}px`;
    selectedBackgroundRef.current.style.display = 'block';
    selectedBackgroundRef.current.classList.add('highlight');
    setTimeout(() => {
      if (selectedBackgroundRef.current) {
        selectedBackgroundRef.current.classList.remove('highlight');
      }
    }, 500);
  }
};


const handleMissionMouseDown = (event, missionId) => {
  if (event.target.classList.contains('keyword')) {
    return;
  }

  event.stopPropagation();
  const missionElement = event.currentTarget;
  const mission = missions.find((m) => m.id === missionId);

  console.log('Mission Created At:', mission.createdAt);
  console.log('Formatted Time Since:', formatTimeSince(mission.createdAt));

  handleVisualUpdate(missionElement, mission?.GiveOrGet);

  if (selectedMissionId !== missionId) {
    setSelectedMissionId(missionId);
    handleMissionSelect(missionId);

    const giveCircle = missionElement.querySelector('.give-circle');
    const getCircle = missionElement.querySelector('.get-circle');
    if (giveCircle) {
      giveCircle.classList.add('add-give-circle');
    }
    if (getCircle) {
      getCircle.classList.add('add-get-circle');
    }

    missionElement.classList.add('expanded');
setClickCounts((prevCounts) => {
  const newCounts = { ...prevCounts, [missionId]: (prevCounts[missionId] || 0) + 1 };
  localStorage.setItem('clickCounts', JSON.stringify(newCounts));
  return newCounts;
});
    selectedMissionRef.current = missionElement;

    const index = missions.findIndex((m) => m.id === missionId);
    const newActiveMissions = missions.slice(Math.max(0, index - 10), Math.min(missions.length, index + 10));
    setActiveMissions(newActiveMissions);

    activeMissions.forEach((m) => {
      if (!newActiveMissions.includes(m)) {
        const element = document.getElementById(`mission-${m.id}`);
        if (element) {
          element.classList.add('hidden');
          element.classList.remove('expanded');
        }
      }
    });

    newActiveMissions.forEach((m) => {
      const element = document.getElementById(`mission-${m.id}`);
      if (element) {
        element.classList.remove('hidden');
      }
    });
  } else {
    navigate(`/${language}/play/${missionId}`);
  }
};



const renderSelectedBackgrounds = () => {
  return Object.keys(clickCounts).flatMap((missionId) => {
    const missionElement = document.getElementById(`mission-${missionId}`);
    if (missionElement) {
      const count = clickCounts[missionId];
      return Array.from({ length: count }).map((_, index) => (
        <div
          key={`${missionId}-${index}`}
          className="selected-background highlight"
          style={{ top: `${missionElement.offsetTop}px` }}
        ></div>
      ));
    }
    return [];
  });
};




  const handleKeywordClick = (keyword) => {
    if (selectedKeyword === keyword) {
      setSelectedKeyword(null);
      setFilteredMissions(missions);
    } else {
      setSelectedKeyword(keyword);
      const filtered = missions.filter((mission) => mission.keywords && mission.keywords.includes(keyword));
      setFilteredMissions(filtered);
    }
  };

useEffect(() => {
  updateSelectedBackground();
}, [filteredMissions, clickCounts]);



  const handleVisualUpdate = (missionElement, missionType) => {
    missionElement.style.borderColor = 'white';

    if (selectedMissionRef.current && selectedMissionRef.current !== missionElement) {
      const previousMissionType = selectedMissionRef.current?.getAttribute('data-mission-type');
      selectedMissionRef.current.style.borderColor = previousMissionType === 'give' ? 'gold' : 'green';
    }

    selectedMissionRef.current = missionElement;
    selectedMissionRef.current.setAttribute('data-mission-type', missionType);
  };

  const calculateCharacterCount = (title) => {
    return title ? title.length : 0;
  };

  const handleMissionSelect = (missionId) => {
    const isNewSelection = selectedMissionId !== missionId;

    if (isNewSelection) {
      const mission = missions.find((m) => m.id === missionId);
      if (!mission) {
        console.error('Mission not found:', missionId);
        return;
      }

      if (!completedMissions.includes(missionId)) {
        setCompletedMissions((prev) => [...prev, missionId]);
      }

      setSelectedMissionId(missionId);
    } else {
      setSelectedMissionId(null);
    }
  };

  const handleBackgroundClick = (event) => {
    if (event.currentTarget === event.target) {
      if (selectedMissionRef.current) {
        const missionType = selectedMissionRef.current?.getAttribute('data-mission-type');
        selectedMissionRef.current.style.borderColor = missionType === 'give' ? 'gold' : 'green';
        selectedMissionRef.current = null;
      }
      setSelectedMissionId(null);
      setUserHasInteracted(true);
      if (selectedBackgroundRef.current) {
        selectedBackgroundRef.current.style.display = 'none';
      }
    }
  };

const selectedMissionDetails = missions.find((mission) => mission.id === selectedMissionId);


return (
<div className='game-concontainer'>
<div className="game-container" onClick={handleBackgroundClick}>
<div className="search-input-wrapper">
  <input
    type="search"
    placeholder=""
    className="search-input"
    onChange={handleSearch}
    onFocus={() => setIsSearchFocused(true)} // När sökfältet får fokus
    onBlur={() => setIsSearchFocused(false)} // När sökfältet förlorar fokus
    ref={searchRef}
  />
  {!isSearchFocused && !searchRef.current?.value && ( // Visa ikonen endast om sökfältet inte är fokuserat och tomt
    <img src={searchIcon} alt="Search" className="search-icon" ref={searchIconRef} />
  )}



</div>

{renderSelectedBackgrounds()}
  <div className="game-background">
    {Array.from({ length: 500 }).map((_, index) => (
      <div key={index} className="rectangle" />
    ))}
  </div>
  <div className="aurora"></div>
  {isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="mission-title-container">
        <MovelandComponent baseConfig={baseConfig} />
{selectedMissionDetails ? (
  <>
    <p className="game-mission-title"></p>
  </>
) : (
  !userHasInteracted && (
    <p className="game-mission-title">{translations[language].missionTitle}</p>
  )
)}

      </div>

      {filteredMissions.map((mission, index) => {
        const isActive = activeMissions.includes(mission);
        const charCount = calculateCharacterCount(mission.title);
        const animationDistance = charCount > 15 ? '110vw' : '90vw';
        const transformValue = mission.GiveOrGet === 'give' ? `translateX(${animationDistance})` : `translateX(-${animationDistance})`;
        const formattedPoints = mission.points > 0 ? `Inkassera +${mission.points}` : `Supporta ${Math.abs(mission.points)}`;
        const isCompleted = completedMissions.includes(mission.id);

        return (
          <div
            key={mission.id}
            id={`mission-${mission.id}`}
            ref={(el) => (missionRefs.current[index] = el)}
            className={`game-mission-item ${mission.GiveOrGet === 'give' ? 'slideGet' : 'slideGive'} ${isCompleted ? 'fade-out' : ''} ${mission.id === selectedMissionId ? 'selected' : ''} ${isActive ? '' : 'hidden'}`}
            style={{
              top: `${index * 305}px`,
              animationName: mission.GiveOrGet === 'give' ? 'slideGet' : 'slideGive',
              animationDuration: mission.animationDuration,
              animationDelay: mission.animationDelay,
              transform: transformValue,
            }}
            onMouseDown={(event) => handleMissionMouseDown(event, mission.id)}
          >
            {mission.GiveOrGet === 'get' && (
              <>
                <div className={`mission-image-container ${mission.GiveOrGet === 'get' ? 'front' : 'back'}`}>
                  {mission.images && mission.images.length > 0 && (
                    <ImageOverlay
                      imageUrl={mission.images[0]}
                      missionId={mission.id}
                      language={language}
                      missionType="get"
                    />
                  )}
                </div>
                <div className="give-circle game-circle"></div>
                {isActive && (
                  <div className="info-mission">
                    <div className="give-info"></div>
                  </div>
                )}
                <p className={`title-inline-mission ${selectedMissionId === mission.id ? 'selected' : ''} ${isCompleted ? 'bold' : ''}`}>{mission.title}</p>
              </>
            )}
            {mission.GiveOrGet === 'give' && (
              <>
                <p className={`title-inline-mission ${selectedMissionId === mission.id ? 'selected' : ''} ${isCompleted ? 'bold' : ''}`}>{mission.title}</p>
                {isActive && (
                  <div className="info-mission">
                    <div className="get-info"></div>
                  </div>
                )}
                <div className="get-circle game-circle"></div>
                <div className={`mission-image-container ${mission.GiveOrGet === 'give' ? 'front' : 'back'}`}>
                  {mission.images && mission.images.length > 0 && (
                    <ImageOverlay
                      imageUrl={mission.images[0]}
                      missionId={mission.id}
                      language={language}
                      missionType="give"
                    />
                  )}
                </div>
              </>
            )}

<div className="div-extra visible">
  {mission.GiveOrGet === 'get' ? (
    <span className='mission-all-info'>
      <div className={`point-distance-right ${selectedMissionId === mission.id ? 'selected' : ''}`}>{formattedPoints} kr</div>
      {selectedMissionId === mission.id && (
        <>
          | {mission.createdAt ? formatTimeSince(mission.createdAt) : 'Invalid date'}{' '}
          {mission.keywords && mission.keywords.map((keyword, idx) => (
            <span key={idx} className={`keyword ${selectedKeyword === keyword ? 'selected' : ''}`} onClick={(e) => { e.stopPropagation(); handleKeywordClick(keyword); }}>
              {keyword}
            </span>
          ))}
        </>
      )}
    </span>
  ) : (
    <span className='mission-all-info'>
      {selectedMissionId === mission.id && (
        <>
          {mission.keywords && mission.keywords.map((keyword, idx) => (
            <span key={idx} className={`keyword ${selectedKeyword === keyword ? 'selected' : ''}`} onClick={(e) => { e.stopPropagation(); handleKeywordClick(keyword); }}>
              {keyword}
            </span>
          ))} | {mission.createdAt ? formatTimeSince(mission.createdAt) : 'Invalid date'}
        </>
      )}
      <div className={`point-distance-left ${selectedMissionId === mission.id ? 'selected' : ''}`}>{formattedPoints} kr</div>
    </span>
  )}
</div>




            <span className={`mission-likes-count ${selectedMissionId === mission.id ? 'selected' : ''}`}>{mission.likes}</span>
          </div>
        );
      })}
    </>
  )}
</div>
</div>
  )}


export default Game;
